import * as React from 'react';
import {Helmet} from 'react-helmet';

const inviteLink = "https://discord.com/invite/eu9B2Jv";

const IndexPage = () => {
  return (
    <main>
        <Helmet>
            <meta name="description" content="Quake For Newbies" />
            <meta name="keywords" content="quake, beginners, newbies, noobs, quake champions, quake live" />
            <meta name="author" content="burtically" />
            <meta property="og:title" content="Quake For Newbies" />
            <meta property="og:description" content="Learn to play Quake." />
            <meta property="og:image" content="https://quakenewbies.com/icons/icon-512x512.png" />
            <meta property="og:type" content="website" />
            <title>Quake For Newbies</title>
        </Helmet>
        <p>Join the <a href={inviteLink} target="_blank" rel="noreferrer">Discord</a>!</p>
    </main>
  )
}

export default IndexPage;
